<template>
  <div class="row">
    <div class="col-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">Asosiy xarajatlar ruyhati</h3>
          </div>
        </div>
        <div class="card-body">
          <v-data-table
            :headers="headers"
            :items="getAllMainExp"
            :search="search"
            class="row-pointer"
            single-select
            @click:row="rowClick"
            item-key="id"
          ></v-data-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
export default {
  data() {
    return {
      counter: 0, // count the clicks
      timer: null, // Needs to be specified here so it can be accessed on both clicks
      selectedId: -1,
      search: '',
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Nomi', value: 'name' }
      ]
    }
  },
  // Action
  beforeCreate() {
    this.$store.dispatch('getAllMainExp')
  },
  computed: {
    getAllMainExp() {
      return this.$store.state.requests.AllMainExp
    }
  },
  methods: {
    rowClick: function (item, row) {
      var self = this
      this.counter++
      if (this.counter == 1) {
        this.timer = setTimeout(function () {
          self.counter = 0
          row.select(true)
          this.selectedId = item.id
        }, 200)
      } else {
        clearTimeout(this.timer)
        self.counter = 0
        row.select(false)
      }
    }
  },
  // SET_BREADCRUMB
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Moliya' },
      { title: 'Asosiy xarajatlar ruyhati' }
    ])
  }
}
// Style
</script>
<style scoped>
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.077) !important;
}
</style>
